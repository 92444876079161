import peter from "../../images/governance/members/Peter.png";
import ricky from "../../images/governance/members/Ricky.png";
import humayun from "../../images/governance/members/Humayun_updated.jpg";
import maria from "../../images/governance/members/Maria.png";
import fetch from "../../images/fetch_Logo.svg";
import bosch from "../../images/Bosch-logo.svg";
import telecom from "../../images/Telekom_logo.svg";
import c4e from "../../images/c4e_logo.svg";
import peaq from "../../images/peaq_logo.svg";
import gameSwift from "../../images/game_swift_logo.svg";

export const members = [
  {
    logo: fetch,
    link: "https://fetch.ai/",
  },
  {
    logo: bosch,
    link: "https://www.bosch.com/",
  },
  {
    logo: telecom,
    link: "https://www.telekom.com/",
  },
  {
    logo: c4e,
    link: "https://c4e.io/",
  },
  {
    logo: peaq,
    link: "https://www.peaq.network/",
  },
  {
    logo: gameSwift,
    link: "https://gameswift.io/",
  }
];
export const Spokedata = [
  {
    name: "Peter Busch",
    position: "Global Product Owner DLT, ",
    company: "bosch",
    profileimageSrc: "/Images/avatar/Humayun.svg",
    content1:
      "Peter is the Lead Product Owner for Distributed Ledger Technologies at the Mobility Strategy CTO office of Bosch. He is working on the future digital mobility connectivity- and data strategies for the Bosch Group.",
    content2:
      "He has 35+ years of experience in international project management, SW/HW development and coaching and training high tech projects. He received his degree in computer science and business administration from the University of Mannheim and worked for his own SW Startup and in different roles at JP Morgan, Sun Microsystems and the BOSCH Group.",
    content3:
      "His current interests are all IoT technologies, Decentralization Ecosystems and their respective business models.",
    profileLink: peter,
  },
  {
    name: "Humayun Sheikh",
    position: "Founder and CEO, ",
    company: "fetch.ai",

    profileimageSrc: "/Images/avatar/Ed.svg",
    content1:
      "Entrepreneur and businessman Humayun Sheikh is the Chief Executive Officer (CEO) and Founder of Fetch.ai. Humayun is considered a visionary with forward-looking and pragmatic approach. He is passionate about AI, machine learning and strongly believes in a paradigm shift in Service Oriented Architecture (SOA). Humayun was an early investor in several tech companies including DeepMind where he supported commercialization of early-stage AI & deep neural network technology. Currently he is putting his experience as an innovator and problem-solver to Build, Deploy & Monetize through an open powerful Fetch.ai platform for the new generation of AI economy.",
    profileLink: humayun,
  },
  {
    name: "Maria Minaricova",
    position: "Director of Business Development,",
    company: "fetch.ai",

    profileimageSrc: "/Images/avatar/Maria.svg",
    content1:
      "Maria Minaricova is a Director of Business Development at Fetch.ai, innovation lab based in Cambridge, developing state-of-the-art technologies using AI Agents technology, Web3 & AI/ML tools. She is building strategic alliances and partnerships with industry as well as academia, focused on solutions for mobility - autonomous / electric vehicles, supply chains and circular economy. Maria is serving on the Board of Directors of the Fetch.ai Foundation and is leading the Fetch.ai activities in the Catena-X consortium and the Gaia-X projects. Maria is a member of the Expert Panel of the EU Blockchain Observatory and Women in AI organization and is passionate about supporting diversity and inclusion in AI & Web3 technologies and beyond.",
    profileLink: maria,
  },
  {
    name: "Ricky Lamberty",
    position: "lead expert digital assets,",
    company: "bosch",

    profileimageSrc: "/Images/avatar/Kamal.svg",
    content1:
      "Ricky Lamberty is Lead Expert in the Finance Transformation Team at Robert Bosch GmbH. Ricky is currently one of Germany's leading experts in Web3 and finance, which led to his inclusion in the Digital Finance Forum of the German Federal Ministry of Finance (BMF) in 2022. His active participation in this forum reflects his commitment in shaping the future of finance. Because of his broad expertise in this area, Ricky was appointed to the board of the Fetch.ai Foundation. In addition, Ricky is an active member of several expert groups in Germany, including the Mirror Group of the Association of German Treasurers (VDT), where he is helping to develop the rulebook for the digital Euro.",
    profileLink: ricky,
  },
];
